import { sha512 } from 'js-sha512';
import jwtDecode from 'jwt-decode';
import ApiService from './api-service';
import config from '../config/config';
import CommonHelper from '../helpers/common-helper';

class AuthService {
  constructor() {
    this.apiService = new ApiService({
      baseURL: process.env.REACT_APP_ACCOUNTS_API,
    });
  }

  /* STAGING & DEVELOPMENT */
  async login() {
    return this.apiService.post({
      data: {
        client_id: process.env.REACT_APP_CLIENT_ID,
        email: process.env.REACT_APP_USER,
        grant_type: `password`,
        password: sha512(process.env.REACT_APP_PW),
      },
      url: config.api.accounts.paths.token,
    });
  }

  async refresh(installationId) {
    return this.apiService.post({
      data: {
        client_id: process.env.REACT_APP_CLIENT_ID,
        grant_type: `refresh_token`,
        refresh_token: localStorage.getItem(`refresh_token`),
      },
      headers: {
        'X-Installation-ID': installationId,
      },
      url: config.api.accounts.paths.token,
    });
  }

  async updateAuthentication(reqConfig) {
    const accessToken = localStorage.getItem(`access_token`);
    const refreshToken = localStorage.getItem(`refresh_token`);
    let data = null;

    if (!accessToken || (refreshToken && CommonHelper.isTokenExpired(refreshToken))) {
      data = await this.login();

      localStorage.setItem(`access_token`, data.access_token);
      localStorage.setItem(`refresh_token`, data.refresh_token);
    }

    if (accessToken && CommonHelper.isTokenExpired(accessToken)) {
      data = await this.refresh(jwtDecode(accessToken).sub);

      localStorage.setItem(`access_token`, data.access_token);
      localStorage.setItem(`refresh_token`, data.refresh_token);
    }

    return {
      ...reqConfig,
      headers: {
        ...reqConfig.headers,
      },
    };
  }
  /* STAGING & DEVELOPMENT END */

  /* PRODUCTION */
  // eslint-disable-next-line class-methods-use-this
  updateProdAuthentication(reqConfig) {
    return {
      ...reqConfig,
      headers: {
        ...reqConfig.headers,
      },
    };
  }
  /* PRODUCTION END */
}

export default new AuthService();
